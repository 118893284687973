.research-page {
    padding: 20px;
}

.books-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Change to match Figma layout */
    gap: 20px;
}

.book-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.book-card img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
}

.book-card p {
    margin-top: 10px;
    text-align: center;
}
