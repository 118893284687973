/* Animation.css */
.animation-container {
    position: relative;
    overflow: hidden;
    height: 50vh; /* Set the height to half of the viewport height */
  }
  
  .moving-image1 {
    position: absolute;
    top: 0;
    left: 0; /* Start from the left edge of the container */
    animation: moveRight1 15s linear infinite; /* Adjust the animation duration as needed */
    height: 60%;
    width: 50%; /* Make the images responsive */
  }
  .moving-image2 {
    position: absolute;
    top: 0;
    left: 0; /* Start from the left edge of the container */
    animation: moveRight2 13s linear infinite; /* Adjust the animation duration as needed */
    height: 80%;
    width: 70%; /* Make the images responsive */
  }
  .moving-image3 {
    position: absolute;
    top: 0;
    left: 0; /* Start from the left edge of the container */
    animation: moveRight3 18s linear infinite; /* Adjust the animation duration as needed */
    height: 60%;
    width: 50%; /* Make the images responsive */
  }
  
  /* Define different sizes for even and odd images */
  .moving-image:nth-child(even) {
    width: 150%; /* 1.5 times the size of the previous image */
  }
  

/* Keyframes for the first image */
@keyframes moveRight1 {
    0% {
      transform: translateX(-100%); /* Start from the left edge of the container */
    }
    100% {
      transform: translateX(200%); /* Move to the right of the container */
    }
  }
  
  /* Keyframes for the second image */
  @keyframes moveRight2 {
    0% {
      transform: translateX(-100%); /* Start from the middle of the container */
    }
    100% {
      transform: translateX(200%); /* Move to the right of the container */
    }
  }
  
  /* Keyframes for the third image */
  @keyframes moveRight3 {
    0% {
      transform: translateX(-200%); /* Start from the right edge of the container */
    }
    100% {
      transform: translateX(200%); /* Move to the right of the container */
    }
  }
  