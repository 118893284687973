/* animations.css */
@keyframes animateUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-200%);
    }
  }
  
  .animated-up {
    animation: animateUp 1.5s ease-out forwards;
  }
  
  /* Add these CSS classes */
.blur-text {
  filter: blur(2px);
  transition: filter 0.3s ease;
}

.blur-text:hover {
  filter: blur(0);
}