/* Define the animation keyframes */
@keyframes gradientAnimation {
    0% {
      background-position: 10% 10%;
    }
    25% {
      background-position: 25% 75%;
    }
    50% {
        background-position: 75% 25%;
    }
    100% {
      background-position: 10% 10%;
    }
  }


  
  /* Apply the gradient animation to an element */
  .animated-gradient {
    background: linear-gradient(to bottom, rgb(93, 92, 92), rgb(212, 207, 207));
    background-size: 100% 200%;
    animation: gradientAnimation 30s ease infinite; /* Adjust the duration as needed */
  }
  